import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Theme } from '@pga/pga-component-library'

const CardWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  border-radius: 0;
  border-right: 1px solid ${Theme.colors.darkgrey};
  @media (max-width: 1199.98px) {
    border-right: none;
  }
`

export const IconCard = ({ item }) => (
  <div className='col-xl-2 col-lg-6 col-md-6 d-flex'>
    <CardWrapper>
      <div className='card-body'>
        <img width={62} height={62} alt={item.title} src={item.icon} className='d-flex mx-auto' />
        <hr />
        <h6 className='mt-3 mb-1 fw-700'>{item.title}</h6>
        <p className='mb-0 lead text-muted'>{item.paragraph}</p>
      </div>
    </CardWrapper>
  </div>
)

IconCard.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string,
    paragraph: PropTypes.string,
    icon: PropTypes.string
  })
}

export default IconCard
