import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import { Theme } from '@pga/pga-component-library'
import { parseFrontMatterFromQuery, parseHtmlFromQuery } from '../utils/index'
import IconCard from '../components/IconCard'

export const StrategicPlanWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem 0;
  h4 {
    font-size: 30px;
    font-weight: 600;
    color: ${Theme.colors.primary};
  }
  > p {
    font-size: 16px;
  }
  hr {
    width: 100%;
  }
`

const CardGray = styled.div.attrs({ className: 'card-body' })`
  background-color: ${Theme.colors.lightBgGrey};
  padding: 1.3rem;
  p {
    color: ${Theme.colors.blueGrey};
  }
  h5 {
    color: ${Theme.colors.primary};
    font-weight: 600;
  }
`

const StrategicPlanImageWrapper = styled.div.attrs({ className: 'col-xl-4' })`
  @media (max-width: 1199.98px) {
    order: -1;
  }
`

const StrategicPlan = ({ data }) => {
  return (
    <div className='card'>
      <div className='container'>
        <StrategicPlanWrapper>
          <h4>PGA of America's Focus</h4>
          <p>Charting the course together - More than 30,000 PGA of America Golf Professionals, 41 Sections, $104B Industry</p>
          <hr />
          <div className='mt-3'>

            <div className='row'>
              {data.map((item, idx) => <IconCard {... { item }} key={idx} />)}
              <StrategicPlanImageWrapper>
                <div className='card shadow-none border-0 mb-0'>
                  <img alt='' className='card-img-top' src='/uploads/mission-hero.jpg' />
                  <CardGray>
                    <h5>Mission</h5>
                    <p>Serve the PGA of America Member and Grow the Game</p>
                    <h5>Purpose</h5>
                    <p>Elevate and advance the PGA of America Member, Profession and Game</p>
                  </CardGray>
                </div>
              </StrategicPlanImageWrapper>
            </div>

          </div>
        </StrategicPlanWrapper>
      </div>
    </div>
  )
}
export default (props) => {
  const { location } = props
  const frontMatter = parseFrontMatterFromQuery(props)
  const pageHtml = parseHtmlFromQuery(props)

  return (
    <Layout {...{ subNavFixed: true, bgColor: Theme.colors.bgGrey, location }}>
      <div dangerouslySetInnerHTML={{ __html: pageHtml }} />
      <div className='container'>
        {frontMatter.strategicPlans && <StrategicPlan data={frontMatter.strategicPlans} />}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: {
        fields: {
          slug: {
            eq: "/"
          }
        }
      }
    ) {
      edges {
        node {
          html
          frontmatter {
            title
            strategicPlans {
              title
              paragraph
              icon
            }
          }
        }
      }
    }
  }
`
